var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[(_vm.order)?_c('v-card',[_c('v-card-title',[_vm._v("Picking Pedido Ref: "+_vm._s(_vm.order.order_reference))]),_c('v-card-text',[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"mr-4",attrs:{"label":"Ingrese o escanee codigo","autofocus":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.toPick($event)}},model:{value:(_vm.scan_field),callback:function ($$v) {_vm.scan_field=$$v},expression:"scan_field"}}),_c('v-btn',{staticClass:"btn-starkoms-primary",on:{"click":_vm.toPick}},[_vm._v("Pickear")])],1)]),_c('v-card-text',[_c('v-data-table',{staticClass:"picking-table",attrs:{"items":_vm.tableData,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.cover_image",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"width":"100px","margin":"10px"},attrs:{"src":item.image_cover}})]}},{key:"item.sku",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.sku)+" ")])]}},{key:"item.pickings",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.picked_quantity)+"/"+_vm._s(item.required)+" ")])]}},{key:"item.done",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[(item.done)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-checkbox-marked-circle-outline")]):_vm._e()],1)]}}],null,false,637901588)})],1),_c('v-card-text',[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"type":"number","step":"1","min":"1","label":"Ingrese el numero de bultos"},model:{value:(_vm.bags),callback:function ($$v) {_vm.bags=$$v},expression:"bags"}})],1)]),_c('v-card-actions',[_c('v-btn',{staticClass:"mr-2",attrs:{"x-large":"","color":"danger"},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"mr-2",attrs:{"x-large":"","color":"primary","disabled":_vm.loading},on:{"click":_vm.sendForm}},[_vm._v("Guardar")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }