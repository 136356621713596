<template>
<div>
    <v-app>
        <v-card>
            <v-card-title>
                Picking Ordenes
                <v-spacer></v-spacer>
                 <v-btn
                @click="clearSelected"
                v-show="selected.length"
                color="error"
                class="float-center mr-3"
                ><v-icon small center dark>mdi-delete</v-icon>Limpiar selección</v-btn>
                <v-btn @click="printPickingPage" color="success" class="float-left" :disabled="!selected.length" >
                    <v-icon left dark>mdi-format-list-checks</v-icon>Imprimir Hoja de Picking ({{selected.length}})

                </v-btn>
                <v-btn class @click="fetchDataPaginated" text icon color="blue">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text >
                <div class="row">
                    <div class="d-flex flex-column col-12 col-sm-10">
                        <v-text-field v-model="search" clearable label="Buscar" hide-details solo class="mr-3" @change="fetchDataPaginated" ></v-text-field>
                    </div>
                    <div class="d-flex flex-column col-12 col-sm-2 mt-2">
                        <v-btn class="btn-starkoms-primary" style="min-width: 138px;" dark @click="fetchDataPaginated">Buscar</v-btn>
                    </div>
                </div>
                <div class="row">
                    <div class="d-flex flex-column col-12 col-sm-4">
                      <span class="text-h6 font-weight-regular black--text"><v-icon>mdi-checkbox-marked-circle-outline</v-icon> Filtro por Estado</span>
                      <v-select label="Filtro por estado" :items="orderStateList" v-model="selectOrderState" item-text="name" solo @change="fetchDataPaginated" ></v-select>
                    </div>
                    <div class="d-flex flex-column col-12 col-sm-4">
                      <span class="text-h6 font-weight-regular black--text"><v-icon>mdi-truck</v-icon> Filtro por courier</span>
                      <v-select
                        class="ml-2 mr-2"
                        label="Filtro por courier"
                        ref="select_carrier"
                        :items="carrierList"
                        v-model="selectCarrier"
                        item-text="text"
                        @input="fetchDataPaginated"
                        solo
                      ></v-select>
                    </div>

                    <div class="d-flex flex-column source_filter col-12 col-sm-4">
                      <span class="text-h6 font-weight-regular black--text "><v-icon>mdi-store</v-icon> Filtro por canal</span>
                      <v-autocomplete
                        v-model="selectSources"
                        :items="sourcesList"
                        chips
                        clearable
                        deletable-chips
                        label=""
                        multiple
                        item-text="name"
                        item-value="id"
                        v-on:change="checkSelection"
                        >
                          <template v-slot:item="data">
                            <template>
                              <v-list-item-content>
                                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>

                    </div>
                    
                    
                    
                </div>
            </v-card-text>
            <v-card-text>
                <v-data-table
                    :items="tableData"
                    :headers="headers"
                    :sort-by="'id'"
                    :sort-desc="true"
                    show-select
                    v-model="selected"
                    @toggle-select-all="selectAllToggle"
                    :disable-pagination="true"
                    :hide-default-footer="true"
                    class="elevation-1 datatable"
                >
                    <template v-slot:item.data-table-select="{ item, isSelected, select }">
                        <v-simple-checkbox
                            :value="isSelected"
                            :readonly="item.disabledSelect"
                            :disabled="item.disabledSelect"
                            v-if="!item.disabledSelect"
                            @input="select($event)"
                            :ripple="false"
                        ></v-simple-checkbox>
                    </template>
                    <template v-slot:item.order_state_name="{ item }">
                        <td class="d-flex flex-column">
                            <v-btn v-if="item.current_state.type == 'DEFAULT_IN_PACKAGING'" dark color="blue">{{item.current_state.name}}</v-btn>
                            <v-btn v-if="item.current_state.type == 'DEFAULT_ON_PACKAGING' || item.current_state.type == 'DEFAULT_ON_PACKAGING_PRINTED'" dark color="orange">{{item.current_state.name}}</v-btn>
                            <v-btn v-if="item.current_state.type == 'DEFAULT_OUT_PACKAGING'" color="success">{{item.current_state.name}}</v-btn>
                        </td>
                    </template>
                    <template v-slot:item.source_name="{ item }">
                        <td class="text-center">
                            {{item.source.name}}
                        </td>
                    </template>
                    <template v-slot:item.created_at="{ item }">
                        <td class="text-center">
                            {{$moment(item.created_at).format('LLLL')}}
                        </td>
                    </template>
                    <template v-slot:item.updated_at="{ item }">
                        <td class="text-center">
                            {{$moment(item.updated_at).format('LLLL')}}
                        </td>
                    </template>
                    <template v-slot:item.bags="{ item }">
                        <td class="text-center">
                            {{item.bags}}
                        </td>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <td class="text-center">
                            <!-- <div v-if="item.bags === undefined || item.bags === null">
                                <v-btn @click="refreshBags(item)" color="red" text><v-icon left>mdi-pencil</v-icon>1. Completar Bultos</v-btn>
                            </div> -->
                            <!-- <div v-else>
                                <v-btn @click="refreshBags(item)" color="purple" text><v-icon left>mdi-pencil</v-icon>Actualizar Bultos</v-btn>
                            </div> -->
                            <v-btn v-if="item.current_state.type == 'DEFAULT_IN_PACKAGING' || item.current_state.type == 'DEFAULT_ON_PACKAGING' || item.current_state.type == 'DEFAULT_ON_PACKAGING_PRINTED'" @click="pickear(item)" color="primary" elevation="2" large block><v-icon left>mdi-arrow-right</v-icon>Pickear</v-btn>
                        </td>
                    </template>
                </v-data-table>
                <div class="d-flex justify-space-between mt-4">
                  <v-select style="max-width: 120px" @input="fetchDataPaginated" v-model="selectItemsPerPage" label="Registros por página" :items="[5,10,20,50,100]"></v-select>
                  <v-pagination @input="fetchDataPaginated" v-model="page" :length="totalPages"></v-pagination>
              </div>
            </v-card-text>
        </v-card>
    </v-app>
    <dialog-pickear @success="fetchDataPaginated"></dialog-pickear>
    <dialog-refresh-bags @success="fetchDataPaginated"></dialog-refresh-bags>
</div>
</template>

<script>
import DialogPickear from '@/views/oms/Picking/DialogPickear.vue';
import DialogRefreshBags from '@/views/oms/Picking/DialogRefreshBags.vue';
export default {
    components:{
        DialogPickear,
        DialogRefreshBags
    },
    data(){
        return {
            tableData: [],
            search: '',
            orderStateList: [],
            selectOrderState: "Todos",
            selected: [],
            disabledCount: 0,
            carrierList: [],
            selectCarrier: '0',
            selectItemsPerPage: 10,
            totalPages: 1,
            page: 1,
            sourcesList: [],
            selectSources: [],
        }
    },
    methods: {
        fetchDataPaginated(){
            var vm = this;
            const sortBy = 'id';
            const sortDesc = true;
            const page = this.page;
            const itemsPerPage = this.selectItemsPerPage;
            const search = this.search;
            const filterState = this.orderStateList.find(e => e.name == this.selectOrderState) ? this.orderStateList.find(e => e.name == this.selectOrderState).id : null;
            const filterCarrier = this.selectCarrier ? this.selectCarrier : null;
            const filterSources = this.selectSources;
            this.axios({
                url: 'picking/paginated/json',
                params: {
                    page,
                    perPage: itemsPerPage,
                    sortBy: sortBy,
                    sortDesc: sortDesc ? 1 : 0,
                    search,
                    filterState,
                    filterCarrier,
                    filterSources,
                },
            }).then( response => {
                vm.tableData = response.data.data;
                vm.totalPages = response.data.last_page;
                vm.disabledCount = vm.tableData.filter((e) => e.disabledSelect).length;
            }).catch( error => {
                console.log(error);
            })
        },
        pickear(item)
        {
            this.$emit('open-dialog-pickear', item);
        },
        refreshBags(item)
        {
            this.$emit('open-dialog-refresh-bags', item);
        },
        fetchOrdersStates() {
            var vm = this;
            this.axios({
                url: "picking/orders_states/filter_list",
            })
            .then((response) => {
                vm.orderStateList = response.data.data;
                vm.orderStateList.unshift({ id: null, name: "Todos", order: null });
            })
            .catch((error) => {
                console.log(error);
                vm.dataTableLoading = false;
            });
        },
        printPickingPage() {
            console.log("printPickingPage");
            this.axios({
                url: "picking/print_picking_page",
                method: "POST",
                data: { orders: this.selected.map((e) => e.id) },
            })
            .then((response) => {
                this.fetchDataPaginated();
                window.open(response.data.pdf, '_blank');
                
            })
            .catch((error) => {
                console.log(error);
            });
        },
        selectAllToggle(props) {
            if(this.selected.length != this.tableData.length - this.disabledCount) {
                this.selected = [];
                const self = this;
                props.items.forEach(item => {
                if(!item.disabledSelect) {
                    self.selected.push(item);
                } 
                });
            } else this.selected = [];
        },
        fetchCarrierList() {
            var vm = this;
            this.axios({
                url: "shippings/carriers/get_data_list",
                method: "GET",
            })
                .then((response) => {
                    //vm.carrierList = response.data;
                    vm.carrierList.push({ text: "Todos", value: '0' });
                    response.data.forEach((item) => {
                        // console.log(item);
                    vm.carrierList.push({
                        text: item.text,
                        value: item.text,
                    });
            });
                })
                .catch((error) => {
                console.log(error);
                });
            },
        fetchSources() {
            var vm = this;

            this.axios({
                url: "admin/stores/default/marketplaces_data",
                method: "GET",
            })
            .then((response) => {
            var mapeado = [];
            var datos = response.data;
            mapeado.push({ id: "Manual", name: "Manual" });
            datos.forEach((item) => {
                mapeado.push({
                id: item.marketplace.name,
                name: item.marketplace.name,
                });
            });
            vm.sourcesList = mapeado;
            })
            .catch((error) => {
            console.log(error);
            vm.dataTableLoading = false;
            });
        },
        checkSelection(){
            var vm = this;
            vm.selectSources.forEach(elem => {
                if(Array.isArray(elem)){
                    vm.selectSources.pop();
                    var isRemove = true;
                    elem.forEach(inelem => {
                        if(!vm.selectSources.includes(inelem)){
                            console.log(inelem);
                            vm.selectSources.push(inelem);
                            isRemove = false;
                        }
                    });
                    console.log(isRemove);
                    if(isRemove){
                        elem.forEach(inelem => {
                            var index = vm.selectSources.indexOf(inelem);
                            if (index !== -1) {
                                vm.selectSources.splice(index, 1);
                            }
                        });
                    }
                }
            });
            this.fetchDataPaginated();
        },
        clearSelected() {
            let vm = this;
            vm.selected = [];
        }
    },
    created(){
        this.fetchDataPaginated();
        this.fetchOrdersStates()
        this.fetchCarrierList();
        this.fetchSources();
    },
    computed:{
        headers(){
            return [
                // {text: 'ID', value: 'id'},
                {text: 'Referencia', value: 'order_reference'},
                {text: 'Estado', value: 'order_state_name'},
                {text: 'Origen', value: 'source_name'},
                {text: 'Courier', value: 'carrier'},
                {text: 'Bultos', value: 'bags'},
                {text: 'Fecha ingreso', value: 'created_at'},
                // {text: 'Fecha Actualizado', value: 'updated_at'},
                {text: 'Acciones', value: 'actions'},
            ];
        }
    }
}
</script>

<style>
.source_filter .v-input--selection-controls{
  margin-top: 0px !important;
  margin-right: 10px;
  max-width: 100px;
  font-weight: bold;
}
.source_filter .v-autocomplete.v-input > .v-input__control > .v-input__slot {
  background-color: #f2f2f2 !important;
  padding: 0px 12px;
  border-radius: 4px !important;
}
.source_filter .v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.source_filter .theme--light.v-label {
  color: black;
}
.source_filter .v-select__slot{
  margin-top: 8px;
}
.source_filter .theme--light.v-text-field > .v-input__control > .v-input__slot:before{
  border-color: rgba(0, 0, 0, 0);
}
 
@media screen and (max-width: 768px) {
   tr.v-data-table__mobile-table-row {
        border: 1px solid #ddd !important;
        display: block!important;
        margin-bottom: 30px;
        box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
    }
    .picking-table .v-data-table__mobile-row__cell img {
        width: 50px !important;
    }
    
    .picking-table td.v-data-table__mobile-row {
        font-size: 16px !important;
    }
}
</style>
</style>